@value first from '../value/values.css';
@value second from '../value/values.css';
@value third from '../value/values.css';
@value dark2 from '../value/values.css';
@value medium from '../value/values.css';

@media(min-width:medium){
    .certy{
        padding: 5% 5%;
        background-color: third;
        margin-bottom: -11px !important;
    }
    .certyColumn{
        position: relative;
    }

    .certyPhoto{
        margin: 11% 10%;
        padding: 2% 5%;
        position: absolute !important;
        z-index: 100 !important;
        cursor: pointer;
    }
    .certyPhoto1{
        margin: 13% 12%;
        padding: 2.5% 5.5%;
    }

    .certyBack{
        width: 85%;
        height: 200px;
        background-color: first;
        position: absolute;
        left: 20px;
        top: 0;
        margin: 18% 23% 20% 23%;
        z-index: 1 !important;
    }
    .certyBody{
        margin-top: 5% !important;
        margin-left: 10% !important;
        line-height: 35px;
        text-align: justify;
        font-size: 17px;
    }
    .green{
        color: first;
        text-align: center;
    }
}
@media(max-width:medium){
    .certy{
        padding: 5% 5%;
        background-color: third;
    }
    .certyColumn{
        position: relative;
    }

    .certyPhoto{
        margin: 11% 10%;
        padding: 20px 5%;
        position: absolute;
        z-index: 100 !important;
        cursor: pointer;
    }
    .certyPhoto1{
        margin: 13% 12%;
        padding: 20px 5%;
    }

    .certyBack{
        width: 85%;
        height: 85%;
        background-color: first;
        position: absolute;
        left: 20px;
        top: 0;
        margin: 18% 23%;
        z-index: 1 !important;
    }
    .green{
        color: first;
        text-align: center;
    }
}